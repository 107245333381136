
import * as TYPES from "./receivablesActionTypes";

const defaultState = {
  BUSINESS_RECEIVABLES: {}
};

export default function business(state = defaultState, action) {
  switch (action.type) {
    case TYPES.BUSINESS_RECEIVABLES:
      return {
        ...state,
        busReceivables: action.value
      };
    default:
      return state;
  }
}
