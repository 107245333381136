import * as TYPES from "./taxesActionTypes";

const defaultState = {
  taxes: [],
  taxesCount: 0
};

export default function todo(state = defaultState, action) {
  switch (action.type) {
    case TYPES.TAXES:
      return {
        ...state,
        taxes: action.value
      };
    case TYPES.TAXES_COUNT:
      return {
        ...state,
        taxesCount: action.value
      };
    default:
      return state
  }
}
