import { publicBucket } from "../../constants";

function getDomain() {
    let hostname = window.location.hostname;
    return hostname === "localhost"
        ? "osrit"
        : hostname.split(".").slice(2, 1)[0];
}

const defaultState = {
    authBusy: false,
    appVersion: process.env.REACT_APP_VERSION,
    isQaOnly: !window.location.hostname.includes("accounting.osrit"),
    baseUrl: "https://osritapi.com/api",
    documentUrl: `https://agreements.${getDomain()}.com`,
    publicBucket
};

export default function app(state = defaultState, action) {
    switch (action.type) {
        default:
            return state;
    }
}
