import * as TYPES from "./sharedActionTypes";

const defaultState = {
  shared: [],
  sharedCount: 0
};

export default function todo(state = defaultState, action) {
  switch (action.type) {
    case TYPES.SHARED:
      return {
        ...state,
        shared: action.value
      };
    case TYPES.SHARED_COUNT:
      return {
        ...state,
        sharedCount: action.value
      };
    default:
      return state
  }
}
